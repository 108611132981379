import classnames from 'classnames'
import * as React from 'react'

import { Icon } from '../icon'
import { PropsWithAs } from '../types'
import { TableAlign } from './types'

const TABLE_HEADER_DEFAULT_ELEMENT = 'th'

interface SortProps {
  isActive: boolean
  direction: 1 | -1
}

function TableSortIndicator({ isActive, direction, className }: Partial<SortProps> & { className?: string }) {
  return (
    <Icon
      name="chevronDown"
      className={classnames(className, 'absolute top-1/2 -mt-2', {
        invisible: !isActive,
        'rotate-180': direction === 1,
      })}
    />
  )
}

interface TableHeaderProps extends Omit<React.HTMLProps<HTMLTableCellElement>, 'as'> {
  isActive?: boolean
  align?: TableAlign
  hasPadding?: boolean
  hasHover?: boolean
}

export function TableHeader<TagType extends React.ElementType = typeof TABLE_HEADER_DEFAULT_ELEMENT>({
  as: asComponent,
  align,
  children,
  isActive,
  hasPadding = true,
  hasHover,
  ...props
}: Omit<PropsWithAs<TagType>, 'align'> & TableHeaderProps) {
  const componentToRender = asComponent ?? TABLE_HEADER_DEFAULT_ELEMENT

  return React.createElement(
    componentToRender,
    {
      ...props,
      className: classnames(
        props.className,
        'whitespace-nowrap font-normal h-5 hover:first:rounded-l-md hover:last:rounded-r-md',
        {
          'cursor-pointer hover:select-none hover:bg-gray-200/30 dark:hover:bg-gray-500/30':
            hasHover && !props.disabled,
          'text-gray-500 dark:text-gray-300': props.disabled,
          'text-gray-600 dark:text-gray-200': !isActive && !props.disabled,
          'font-medium text-gray-800 dark:text-gray-50': isActive,
          'text-left': align === TableAlign.Left,
          'text-center': align === TableAlign.Center,
          'text-right': align === TableAlign.Right,
          'px-1': hasPadding,
          'p-0': !hasPadding,
        }
      ),
    },
    children
  )
}

export function TableHeaderSortable<TagType extends React.ElementType = typeof TABLE_HEADER_DEFAULT_ELEMENT>({
  direction,
  ...props
}: Omit<PropsWithAs<TagType>, 'align'> & Partial<SortProps> & TableHeaderProps) {
  const isDisabled = props.disabled === true

  return (
    <TableHeader
      {...(props as any)}
      hasHover={true}
      className={classnames(props.className, {
        'hover:text-gray-800 dark:hover:text-gray-50': !props.isActive && !isDisabled,
        'pl-4': props.align === TableAlign.Right,
        'pr-4': props.align !== TableAlign.Right,
      })}
    >
      <div
        className={classnames('relative inline-block', {
          'translate-x-1.5': props.align === TableAlign.Center && !props.isActive,
        })}
      >
        {props.align === TableAlign.Right && (
          <TableSortIndicator isActive={props.isActive} direction={direction} className="right-full" />
        )}
        {props.children}
        {props.align !== TableAlign.Right && (
          <TableSortIndicator isActive={props.isActive} direction={direction} className="left-full" />
        )}
      </div>
    </TableHeader>
  )
}
