import { waitForPreviousPromise } from '../../main/services/wait-for-previous-promise'

function getCurrentDateInMs() {
  return new Date().valueOf()
}

function getIsDocumentVisible() {
  return document.visibilityState === 'visible' || document.hidden === false
}

/**
 * Invoke a callback when page visibility changes. Turns the callback into a promise
 * and waits for the previous promise to finish before invoking it again
 */
export function notifyOnVisibilityChange(callback: (visible: boolean) => void | Promise<void>) {
  const promisifiedCallback = waitForPreviousPromise(() => callback(getIsDocumentVisible()))

  document.addEventListener('visibilitychange', promisifiedCallback)

  return {
    callback: promisifiedCallback,
    unsubscribe: () => {
      document.removeEventListener('visibilitychange', promisifiedCallback)
    },
  }
}

/**
 * Calls callback based on interval and document visibility. Returns unsubscribe function.
 * How it works:
 * - document is hidden: interval cleared
 * - document visible: interval set-up with remaining time from last interval. Call immediately if interval elapsed
 */
export function intervalCallbackOnWindowVisible(
  interval: number | (() => number),
  callback: () => void | Promise<void>
) {
  const getInterval = typeof interval === 'function' ? interval : () => interval
  let refreshPromise: Promise<void> | void | null = null
  let nextRefresh = getCurrentDateInMs() + getInterval()
  let refreshTimeout: number | null = null

  async function refresh() {
    nextRefresh = getCurrentDateInMs() + getInterval()
    // Call callback asynchronously
    refreshPromise = callback()
    await refreshPromise
    refreshPromise = null

    // Queue next refresh
    if (getIsDocumentVisible()) {
      refreshTimeout = window.setTimeout(refresh, Math.max(0, nextRefresh - getCurrentDateInMs()))
    }
  }

  /**
   * Handle the visibility change event, compute callback remaining time or call immediately
   */
  function handleVisibilityChange() {
    if (refreshTimeout) clearTimeout(refreshTimeout)

    if (getIsDocumentVisible() && refreshPromise === null) {
      const currentDateMs = getCurrentDateInMs()
      // Refresh if the document is stale, otherwise set a timeout to refresh later
      if (nextRefresh <= currentDateMs) {
        refresh()
      } else {
        refreshTimeout = window.setTimeout(refresh, nextRefresh - currentDateMs)
      }
    }
  }

  if (getIsDocumentVisible()) {
    refreshTimeout = window.setTimeout(refresh, getInterval())
  }

  document.addEventListener('visibilitychange', handleVisibilityChange)

  return () => {
    if (refreshTimeout) clearTimeout(refreshTimeout)
    document.removeEventListener('visibilitychange', handleVisibilityChange)
  }
}
