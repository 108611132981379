import * as React from 'react'

import { SortDirectionValue, SortableValue } from '../types'
import { SortByColumnOptions, sortByColumn } from '../util'

export interface SortByShape<IdType, DataType> {
  id: IdType

  /**
   * Provide value calculation for sort when value is computed in column renderer
   */
  getValueForSort?: (row: DataType) => SortableValue
}

export function isSortByShape<IdType, DataType>(sortBy?: unknown): sortBy is SortByShape<IdType, DataType> {
  return sortBy?.hasOwnProperty?.('id') === true
}

/**
 * Default value getter which gets row data by `getValueForSort` defined on the column
 * If no sort value getter is provided, it falls back to getting data by column.id or returns the whole row
 */
export function getDefaultValueGetter<DataType, SortBy>(column: SortBy) {
  return (row: DataType): SortableValue => {
    let value = null

    if (isSortByShape(column)) {
      if (typeof column.getValueForSort === 'function') value = column.getValueForSort(row)
      else if (typeof row === 'object') value = row?.[column.id as keyof typeof row]
    } else {
      value = row
    }

    return (value as SortableValue) ?? null
  }
}

export function useSortData<DataType, SortBy>({
  data,
  sortBy,
  direction,
  valueGetter = getDefaultValueGetter,
  options,
}: {
  data: DataType[]
  sortBy?: SortBy | null
  direction: SortDirectionValue
  valueGetter?: (sortBy: SortBy) => (row: DataType) => SortableValue
  options?: SortByColumnOptions
}) {
  return React.useMemo(() => {
    if (!sortBy) return [...data]

    return [...data].sort((a, b) =>
      sortByColumn<DataType>({
        ...options,
        a,
        b,
        direction,
        valueGetter: valueGetter(sortBy),
      })
    )
  }, [data, options, direction, valueGetter, sortBy])
}
